import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

import Layout from "../layout";
import PostListing from "../components/PostListing";
import SEO from "../components/SEO";
import config from "../../blogposts/data/SiteConfig";
import git from "../../blogposts/thumbnails/git.png";
import javascript from "../../blogposts/thumbnails/js.png";
import react from "../../blogposts/thumbnails/react.png";
import sql from "../../blogposts/thumbnails/sql.png";
import strapi from "../../blogposts/thumbnails/strapi.png";
import seo from "../../blogposts/thumbnails/seo.png";
import youtube from "../../blogposts/thumbnails/youtube.png";

import UserInfo from "../components/UserInfo";
import ViewCounter from "../components/ViewCounter";

const manuals = [
  { name: "Youtube", image: youtube, url: "/my-youtube-contents" },
  {
    name: "Strapi",
    image: strapi,
    url: "/introduction-to-strapi-a-nodejs-headless-cms",
  },
  {
    name: "Javascript",
    image: javascript,
    url: "/fundamentals-of-javascript-every-developer-should-know/",
  },
  { name: "Git", image: git, url: "/getting-started-with-git/" },
  { name: "SEO", image: seo, url: "/seo-audit-checklist/" },
  { name: "SQL", image: sql, url: "/mysql-tutorial-for-beginners" },
];

const interviewQuestions = [
  {
    name: "Javascript",
    image: javascript,
    url: "/javascript-interview-questions/",
  },
  {
    name: "ReactJS",
    image: react,
    url: "/reactjs-interview-questions/",
  },
];

export default class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      posts: this.props.data.posts.edges,
      filteredPosts: this.props.data.posts.edges,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => this.filterPosts());
  };

  filterPosts = () => {
    const { posts, searchTerm } = this.state;

    const filteredPosts = posts.filter((post) =>
      post.node.frontmatter.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    this.setState({ filteredPosts });
  };

  render() {
    const { filteredPosts, searchTerm } = this.state;
    const filterCount = filteredPosts.length;
    const categories = this.props.data.categories.group;

    return (
      <Layout>
        <Helmet title={`Articles – ${config.siteTitle}`} />
        <SEO />
        <span style={{ display: "none" }}>
          <ViewCounter id="articles" />
        </span>
        <div className="gradient-section articles">
          <div className="container">
            <h2 className="text-center">
              The Missing Instruction Manuals of the Web
            </h2>
            <div className="instruction-manuals">
              {manuals.map((manual) => (
                <Link key={manual.url} to={manual.url}>
                  <img src={manual.image} alt={manual.name} />
                  <h3>{manual.name}</h3>
                </Link>
              ))}
            </div>

            <h2 className="text-center">Interview Questions and Answers</h2>
            <div className="instruction-manuals">
              {interviewQuestions.map((manual) => (
                <Link key={manual.url} to={manual.url}>
                  <img src={manual.image} alt={manual.name} />
                  <h3>{manual.name}</h3>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <h1 className="articles-title">Articles</h1>
          <div className="category-container">
            {categories.map((category) => (
              <Link
                to={`/categories/${category.fieldValue.toLowerCase()}`}
                className="category-filter"
                key={category.fieldValue}
              >
                {category.fieldValue}
              </Link>
            ))}
          </div>
          <div className="search-container">
            <input
              className="search"
              type="text"
              name="searchTerm"
              value={searchTerm}
              placeholder="Type here to filter posts..."
              onChange={this.handleChange}
            />
            <div className="filter-count">{filterCount}</div>
          </div>
          <PostListing postEdges={filteredPosts} />
        </div>
        <UserInfo config={config} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query BlogQuery {
    posts: allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 180)
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 50, height: 50) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    categories: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`;
